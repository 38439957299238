"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _workboxWindow = require("workbox-window");

/* eslint-disable no-console */
// import { unregister } from 'register-service-worker'
// if (process.env.NODE_ENV === 'production') {

/* register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
    )
  },
  registered() {
    console.log('Service worker has been registered.')
  },
  cached() {
    console.log('Content has been cached for offline use.')
  },
  updatefound() {
    console.log('New content is downloading.')
  },
  updated() {
    console.log('New content is available; please refresh.')
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  }
}) */
function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  return fetch(swUrl, {
    headers: {
      'Service-Worker': 'script'
    }
  }).then(response => {
    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type');

    if (response.status === 404 || contentType != null && contentType.indexOf('javascript') === -1) {
      // No service worker found. Probably a different app. Reload the page.
      return navigator.serviceWorker.ready.then(registration => {
        return registration.unregister().then(() => {
          window.location.reload();
          return false;
        });
      });
    } else {
      // Service worker found. Proceed as normal.
      return true;
    }
  }).catch(() => {
    console.warn('No internet connection found. App is running in offline mode.');
    return true;
  });
}

let wb = null;

if ('serviceWorker' in navigator) {
  wb = new _workboxWindow.Workbox('/service-worker.js?_==v');
  checkValidServiceWorker('/service-worker.js?_==d_').then(isValid => {
    if (isValid) {
      wb.addEventListener('controlling', () => {
        window.location.reload();
      }); // unregister()

      wb.register().then(() => {
        /* if (process.env.NODE_ENV !== 'production') {
          console.log('ServiceWorker registration successful: ', registration)
        } */
      }, err => {
        if (process.env.NODE_ENV !== 'production') {
          console.warn('ServiceWorker registration failed: ', err);
        }

        navigator.serviceWorker.ready.then(registration => {
          registration.unregister();
        }).catch(error => {
          console.error(error.message);
        });
      });
    }
  });
} else {
  wb = null;
} // }


var _default = wb;
exports.default = _default;