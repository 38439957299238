"use strict";

var _router = _interopRequireDefault(require("./router"));

var _store = _interopRequireDefault(require("./store"));

var _elementUi = require("element-ui");

var _nprogress = _interopRequireDefault(require("nprogress"));

require("nprogress/nprogress.css");

var _auth = require("@/utils/auth");

var _getPageTitle = _interopRequireDefault(require("@/utils/get-page-title"));

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// progress bar
// progress bar style
// get token from cookie
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration


const whiteList = ['/login', '/forget-password', '/reset-password']; // no redirect whitelist

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

function flattenRoutes(routes) {
  return routes.reduce((resultRoutes, r) => {
    const route = { ...r
    };

    if (isFunction(route.component)) {
      // continue flatten
      if (Array.isArray(route.children)) {
        const children = flattenRoutes([...route.children]).map(subRoute => {
          if (subRoute.path && subRoute.path[0] !== '/' && route.path) {
            subRoute.path = route.path + '/' + subRoute.path;
          }

          return subRoute;
        });
        delete route.children;
        resultRoutes.push(route);
        return resultRoutes.concat([...children]);
      }
    } else if (Array.isArray(route.children)) {
      resultRoutes.push({ ...route,
        children: flattenRoutes([...route.children])
      });
      return resultRoutes;
    }

    resultRoutes.push(route);
    return resultRoutes;
  }, []);
}

_router.default.beforeEach(async (to, from, next) => {
  // console.log('entering router.beforeEach', i18n.locale)
  // start progress bar
  _nprogress.default.start(); // set page title


  document.title = (0, _getPageTitle.default)(to.meta.title ? _i18n.default.t('route.' + to.meta.title) : ''); // determine whether the user has logged in

  const hasToken = (0, _auth.getToken)();

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({
        path: '/'
      });

      _nprogress.default.done();
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = _store.default.getters.roles && _store.default.getters.roles.length > 0;

      if (hasRoles) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          _store.default.dispatch('user/getInfo').then(async ({
            permissions,
            tenant
          }) => {
            if (permissions && Array.isArray(permissions)) {
              // generate accessible routes map based on roles
              const accessRoutes = await _store.default.dispatch('permission/generateRoutes', {
                roles: permissions,
                tenant
              }); // console.log({ accessRoutes })
              // dynamically add accessible routes

              _router.default.addRoutes([...flattenRoutes([...accessRoutes])]); // hack method to ensure that addRoutes is complete
              // set the replace: true, so the navigation will not leave a history record


              next({ ...to,
                replace: true
              });
            }
          }).catch(err => {
            if (!err) {
              return new Promise((resolve, reject) => {
                if (window.requestIdleCallback) {
                  requestIdleCallback(() => {
                    _store.default.dispatch('user/getInfo').then(result => resolve(result)).catch(err => reject(err));
                  });
                } else {
                  setTimeout(() => {
                    _store.default.dispatch('user/getInfo').then(result => resolve(result)).catch(err => reject(err));
                  }, 180);
                }
              }).catch(() => {
                next(`/login?redirect=${to.path}`);

                _nprogress.default.done();
              });
            }

            console.info(err, err.message);
            next(`/login?redirect=${to.path}`);

            _nprogress.default.done();

            return Promise.reject(err);
          });
        } catch (error) {
          // remove token and go to login page to re-login
          await _store.default.dispatch('user/resetToken');

          if (error.status && error.status === 401) {
            _elementUi.Message.error('Login Expired'); // hasToken already checked

          } else if (error.status) {
            _elementUi.Message.error(error.message || JSON.stringify(error) || 'Has Error');
          } else if (error.message) {
            _elementUi.Message.error(error.message || 'Has Error');
          } else {
            _elementUi.Message.error(JSON.stringify(error) || 'Has Error');
          }

          next(`/login?redirect=${to.path}`);

          _nprogress.default.done();
        }
      }
    }
  } else {
    /* has no token*/
    if (_store.default.getters.refresh_token) {
      await _store.default.dispatch('user/removeRefreshToken');
    }

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);

      _nprogress.default.done();
    }
  }
});

_router.default.afterEach(() => {
  // finish progress bar
  _nprogress.default.done();
});