"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  isStandalone: state => state.app.isStandalone,
  token: state => state.user.token,
  refresh_token: state => state.user.refresh_token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  user: state => state.user,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  lessonToEdit: state => state.lesson.lessonToEdit
};
var _default = getters;
exports.default = _default;