"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;

var _router = require("@/router");

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role));
  } else {
    return true;
  }
}
/**
 * Use meta.tenant to determine if the current user has tenancy
 * @param tenant
 * @param route
 */


function hasTenancy(tenant, route) {
  if (route.meta && route.meta.tenants) {
    return route.meta.tenants.includes(tenant.name);
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 * @param tenant flag/id
 */


function filterAsyncRoutes(routes, roles, tenant) {
  const res = [];
  routes.forEach(route => {
    const tmp = { ...route
    };
    let target = null;

    if (tmp.path === '/' && tmp.redirect === '/default') {
      if (roles.includes('Admin')) {
        target = '/dashboard';
      } else if (roles.includes('Operation Manager')) {
        target = '/dashboard';
      } else if (roles.includes('Floor Manager')) {
        target = '/dashboard';
      } else if (roles.includes('Warehouse Manager')) {
        target = '/dashboard';
      } else if (roles.includes('Store Manager')) {
        target = '/stores';
      } else if (roles.includes('Procurement Manager')) {
        target = '/dashboard';
      } else {
        target = '/dashboard';
      }

      if (target !== tmp.redirect) {
        tmp.redirect = target;
      }
    }

    if (hasPermission(roles, tmp) && hasTenancy(tenant, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles, tenant);
      }

      res.push(tmp);
    }
  });
  return res;
}

const state = {
  routes: [],
  addRoutes: []
};
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
const actions = {
  generateRoutes({
    commit
  }, params) {
    const {
      roles,
      tenant
    } = params;
    return new Promise(resolve => {
      let accessedRoutes;

      if (roles.includes('admin')) {
        accessedRoutes = _router.asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles, tenant);
      }

      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }

};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;