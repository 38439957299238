"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// import defaultSettings from '@/settings'
// const { showSettings, fixedHeader, sidebarLogo } = defaultSettings
const state = {
  lessonToEdit: null
};
const mutations = {};
const actions = {};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;